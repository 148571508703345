export default function getPresetFromCropping (crop: string | undefined) {
  if (!crop) {
    return '';
  }
  if (crop === '3:4' || crop === '9:16' || crop === 'slim') {
    return 'slim';
  }
  if (crop === '4:3' || crop === '16:9' || crop === 'wide') {
    return 'wide';
  }
  if (crop === '1:1' || crop === 'square') {
    return 'square';
  }
  return '';
}
