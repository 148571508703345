import getPresetFromCropping from '~/common/getPresetFromCropping';

export default async function useStatamicImage (id?: string, cropping?: string) {
  if (!id) {
    return {
      src: '',
      srcset: ''
    };
  }
  const config = useRuntimeConfig();

  const ratio = getPresetFromCropping(cropping);

  const imageSm = await getImageUrl(id, ratio ? `sm_${ratio}` : 'sm', config.public.cmsBaseUrl, config.public.thumbnailBaseUrl);
  const imageMd = await getImageUrl(id, ratio ? `md_${ratio}` : 'md', config.public.cmsBaseUrl, config.public.thumbnailBaseUrl);
  const imageLg = await getImageUrl(id, ratio ? `lg_${ratio}` : 'lg', config.public.cmsBaseUrl, config.public.thumbnailBaseUrl);

  const sources = {
    '640w': imageSm,
    '1024w': imageMd,
    '1800w': imageLg
  };

  return {
    src: getSrc(),
    srcset: config.public.appEnv === 'production' ? getSourceSet() : ''
  };

  function getSrc (width: '640w' | '1024w' | '1800w' = '1800w') {
    return sources[width];
  }

  function getSourceSet () {
    return Object.entries(sources)
      .map((value) => {
        return `${value[1]} ${value[0]}`;
      })
      .join(', ');
  }
}

function getImageUrl (id: string, preset: string, cmsUrl: string, thumbnailUrl: string) {
  const redirectUrl = `${thumbnailUrl}${preset}/${btoa(id)}?urlOnly=1`;

  return $fetch<string>(redirectUrl)
    .then((r) => {
      return `${cmsUrl}${r.slice(1)}`;
    })
    .catch((e) => {
      console.log('error', e);
      return 'no-image';
    });
}
