export default function useStatamicOgImage (id: string) {
  const config = useRuntimeConfig();

  const imageSrc = getImageSrc();

  return imageSrc;

  function getImageSrc () {
    return `${config.public.thumbnailBaseUrl}og_image/${btoa(id)}`;
  }
}
