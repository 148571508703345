interface StatamicApiOptions {
  id: string;
  collection: string;
}

export default async function useStatamicEntryApi<T> (options: StatamicApiOptions, locale: string): Promise<T | null> {
  const config = useRuntimeConfig();
  let url = `${config.public.apiBaseUrl}collections/${options.collection}/entries/${options.id}?site=${locale}`;

  const hasPreview = config.public.previewMode === 'true';
  if (hasPreview) {
    url += '&preview=true';
  }

  if (!options.id) {
    throw showError({ statusCode: 400, statusMessage: `Content with id ${options.id} does not exist` });
  }

  const { data, error } =
    await useAsyncData(`${options.collection}-${options.id}`, (): Promise<{ data: T }> => ($fetch(url)));

  if (error.value?.message) {
    console.error(`[entry] ${options.collection}-${options.id} has error`, error.value.message);

    throw showError({ statusCode: 400, statusMessage: error.value.message });
  }

  return data.value?.data ?? null;
}
