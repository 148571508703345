import { type StatamicSEO } from '~/content/StatamicTypes';
import useStatamicOgImage from '~/content/useStatamicOgImage';

type StructuredDataJobPostingLocation = {
  '@type': 'Place',
  address: {
    '@type': string;
    streetAddress: string;
    addressLocality: string;
    postalCode: string;
    addressCountry: string;
  }
}

type StructuredDataJobPostingHiringOrganization = {
  '@type': 'Organization',
  name: string;
  sameAs: string;
  logo: string;
}

type StructuredDataJobPostingIdentifier = {
  '@type': 'PropertyValue';
  name: string;
  value: string;
}

type StructuredDataJobPostingContactPoint = {
  '@type': 'ContactPoint';
  email: string;
}

type StructuredDataJobPosting = {
  '@context': 'https://schema.org/';
  '@type': 'JobPosting';
  title: string,
  description: string;
  identifier?: StructuredDataJobPostingIdentifier;
  datePosted: string;
  validThrough?: string;
  employmentType?: string;
  hiringOrganization: StructuredDataJobPostingHiringOrganization;
  jobLocation: StructuredDataJobPostingLocation | StructuredDataJobPostingLocation[];
  applicationContact: StructuredDataJobPostingContactPoint;
  jobImmediateStart: boolean;
  directApply: boolean;
}

export default function handleSeo (s: StatamicSEO | null, overwrite: Partial<StatamicSEO> = {}, structuredData?: StructuredDataJobPosting) {
  const seo = { ...s, ...overwrite };

  const config = useRuntimeConfig();
  const siteUrl = config.public.siteUrl;

  if (seo.image) {
    if (seo.image.id) {
      const thumbnailUrl = useStatamicOgImage(seo.image.id);
      seo.image.url = thumbnailUrl;
    } else if (!seo.image.url) {
      delete seo.image;
    }
  }

  useHead({
    title: `${seo?.title} | Gridonic`,
    meta: [
      { property: 'og:title', content: `${seo?.title} | Gridonic` },
      { name: 'description', content: truncateMetaDescription(seo?.description || '') },
      { property: 'og:description', content: truncateMetaDescription(seo?.description || '') },
      { property: 'og:image', content: seo?.image?.url ?? `${siteUrl}images/default_og_image.jpg` },
      { property: 'og:locale', content: 'de_CH' },
      { property: 'og:site_name', content: 'Gridonic' }
    ]

  });

  if (structuredData) {
    useHead({
      script: [
        { innerHTML: JSON.stringify(structuredData), type: 'application/ld+json' }
      ]
    });
  }
}

function truncateMetaDescription (d: string) {
  if (d.length > 160) {
    const maxLength = 155;
    return d.substring(0, maxLength) + '...';
  }

  return d;
}
